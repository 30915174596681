import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api/api";
import ITravelTime from "components/map/map-controls/map-tools/travel-time/ITravelTime";
import {
  IModuleDataResponse,
  IConveymapSession,
  ILayer,
  ModuleTypes,
  ConveyMapSessionViewLayer,
  IFilterValue,
  ISessionFeature,
  FilterType,
  IUndewritingTitleSession,
  UnderwritingTitleGroup,
  UnderwritingState,
  IAddOrderReportTitle,
  IAddOrderReportDetails,
  IFeature,
} from "../../api/contracts";
import notification from "app/helpers/notification";
import { createGeometry } from "util/geometry";

export const getSession = createAsyncThunk(
  "conveymap/getSession",
  async (sessionId: string) => {
    return await api.conveymap.getSession({ sessionId });
  }
);

export const getLayers = createAsyncThunk(
  "conveymap/getLayers",
  async (moduleId: number) => {
    return await api.geoServer.getModuleData({ moduleId });
  }
);

export const lookupAddress = createAsyncThunk(
  "rol/lookupAddress",
  async (titleNumber: string) => {
    const addresses = await api.lookupAddress({ titleNumber });
    return { addresses, titleNumber };
  }
);

export const addLocatorFeature = createAsyncThunk(
  "locator/addLocatorFeature",
  async (
    { features, address }: { features: IFeature[]; address?: string | null },
    { dispatch, getState }
  ) => {
    const { conveymapSlice } = getState() as {
      conveymapSlice: { session: IConveymapSession };
    };
    const state = conveymapSlice.session;
    const status = state.session_status?.toLocaleLowerCase();
    const allowEdit = Boolean(status === "new" || status === "ready");

    if (state.order_report_mode && features.length > 0 && allowEdit) {
      if (features.length > 1) {
        dispatch(addUpdateFeatures(features));
      } else {
        const feature = features[0];
        const properties = feature.properties;
        const { WKT } = createGeometry(feature);
        if (properties.title_no !== state.session_order_report?.TitleNumber) {
          dispatch(
            addOrderReportTitle({
              Tenure: properties.tenure,
              TitleNumber: properties.title_no,
              WKT,
              address,
            })
          );
        }
      }
    }
  }
);

export const saveSession = createAsyncThunk(
  "conveymap/saveSession",
  async (
    {
      reference,
      center,
      zoom,
      features,
      moduleId,
      underwrittingMode,
      orderReportMode,
    }: {
      features?: ISessionFeature[];
      reference?: string;
      center?: number[];
      zoom?: number;
      moduleId?: ModuleTypes;
      underwrittingMode?: boolean;
      orderReportMode?: boolean;
    },
    { dispatch, getState }
  ) => {
    if (reference) dispatch(setReference(reference));
    if (zoom) dispatch(setZoom(zoom));
    if (center) dispatch(setCenter(center));
    if (features) dispatch(setFeatures(features));
    if (moduleId) dispatch(setModuleId(moduleId));
    if (underwrittingMode) dispatch(setUnderwrittingMode(underwrittingMode));
    if (orderReportMode) dispatch(setOrderReportMode(orderReportMode));
    const { conveymapSlice } = getState() as {
      conveymapSlice: { session: IConveymapSession };
    };

    return await api.conveymap.saveSession({
      ...conveymapSlice.session,
      filters: undefined,
    });
  }
);

const initialSession: IConveymapSession = {
  reference: "",
  zoom: 16,
  position: [426422, 270360],
  base: { layer_id: "", filters: [], opacity: 1 },
  layers: [],
  historic_layers: [],
  module_id: ModuleTypes.ConveyMap,
  features: [],
  underwriting_mode: false,
  order_report_mode: false,
  session_underwriting: null,
  session_order_report: null,
};
const initialLayers: IModuleDataResponse = { groups: [], profile: "" };
const initialСache: ConveyMapSessionViewLayer[] = [];
const initialPanelsOpen = {
  isFiltersPanelOpen: false,
  isOrderPanelOpen: false,
};
const initialHistoricalLayers: { county: string[]; national: string[] } = {
  county: [],
  national: [],
};
const initialOrderReport = {
  loading: false,
  maxSqm: 0,
  maxAddressCount: 0,
};

export enum ConveymapTab {
  Order = 0,
  Selection = 1,
  Filters = 2,
}

const conveymapSlice = createSlice({
  name: "conveymap",
  initialState: {
    initialSession: initialSession,
    session: initialSession,
    layers: initialLayers,
    historical: initialHistoricalLayers,
    cache: initialСache, // store filters value for removed layers
    panelsOpenStates: initialPanelsOpen,
    acceptLayer: null as ILayer | null,
    travelTimeModeOptions: {} as ITravelTime,
    storymap: false,
    underwrittingPanelActive: false,
    activeTab: ConveymapTab.Selection,
    isNewSession: false,
    features: [] as IFeature[],
    openLocator: false,
    usedLocatorSearch: false,
    locatorCountry: "" as string,
    order_report: initialOrderReport,
  },
  reducers: {
    setIsNewSession: (state, action: PayloadAction<boolean>) => {
      state.isNewSession = action.payload;
    },
    setUnderwrittingPanelActive: (state, action: PayloadAction<boolean>) => {
      state.underwrittingPanelActive = action.payload;
    },
    setOpenLocator: (state, action: PayloadAction<boolean>) => {
      state.openLocator = action.payload;
    },
    addUnderwritingTitle: (
      state,
      action: PayloadAction<IUndewritingTitleSession>
    ) => {
      if (state.session.session_underwriting) {
        state.session.session_underwriting.Titles.push(action.payload);
      } else {
        state.session.session_underwriting = {
          Titles: [action.payload],
          State: UnderwritingState.NewState,
          PlaceDDAOrder: false,
          ShowOnAllTabs: false,
        };
      }
    },
    setActiveTab: (state, action: PayloadAction<ConveymapTab>) => {
      state.activeTab = action.payload;
    },
    removeUnderwritingTitle: (
      state,
      action: PayloadAction<{ titleNumber: string }>
    ) => {
      if (!state.session.session_underwriting) return;

      const t_index = state.session.session_underwriting?.Titles.findIndex(
        (c) => c.TitleNumber === action.payload.titleNumber
      );
      if (t_index !== -1) {
        state.session.session_underwriting?.Titles.splice(t_index, 1);
      }
      const f_index = state.session.features.findIndex(
        (c) => c.title === action.payload.titleNumber
      );
      if (f_index !== -1) {
        state.session.features.splice(f_index, 1);
      }
    },
    setAcceptLayer: (state, action: PayloadAction<ILayer | null>) => {
      state.acceptLayer = action.payload;
    },
    setGreyScale: (state, action: PayloadAction<boolean>) => {
      state.session.base.is_grey_scale = action.payload;
    },
    setHistoricLayers: (
      state,
      action: PayloadAction<{ county: string[]; national: string[] }>
    ) => {
      state.historical = action.payload;
    },
    setLayers: (state, action: PayloadAction<ConveyMapSessionViewLayer[]>) => {
      state.session.layers = action.payload;
    },
    addDropdownLayer: (
      state,
      action: PayloadAction<{ layer: ILayer; key: string; is_active: boolean }>
    ) => {
      /* Adds parent layer of dropdowns in layers array */
      /* Update's the dropdown array within with updated is_active states */
      /* Removes parent layer from layers array if no active states within dropdown */

      const { layer, key, is_active } = action.payload;
      const current = state.session.layers.find((x) => x.layer_id === layer.id);
      if (current) {
        const hasDropdown = current?.dropdown && current?.dropdown.length > 0;
        const item = hasDropdown ? current : layer;
        const dropdown = item.dropdown?.map((item) => {
          if (item.key === key) {
            return {
              ...item,
              is_active,
            };
          } else return item;
        });

        const tmp = state.session.layers.filter((x) => x.layer_id !== layer.id);
        tmp.push({
          layer_id: layer.id,
          filters: [],
          opacity: 1,
          dropdown,
        });
        state.session.layers = tmp;
      }
    },
    addLayer: (state, action: PayloadAction<ILayer>) => {
      const exist = state.session.layers.find(
        (c) => c.layer_id === action.payload.id
      );
      if (exist) return;
      const f = state.cache.find((c) => c.layer_id === action.payload.id);
      if (f)
        state.session.layers.push({
          ...f,
          dropdown: action.payload.dropdown ?? [],
        });
      else
        state.session.layers.push({
          layer_id: action.payload.id,
          filters: [],
          opacity: 1,
          dropdown: action.payload.dropdown ?? [],
        });
    },
    removeLayer: (state, action: PayloadAction<ILayer>) => {
      state.acceptLayer = null;
      const index = state.session.layers.findIndex(
        (layer) => layer.layer_id === action.payload.id
      );
      const f_index = state.cache.findIndex(
        (c) => c.layer_id === action.payload.id
      );
      if (f_index !== -1) state.cache[f_index] = state.session.layers[index];
      else state.cache.push(state.session.layers[index]);
      state.session.layers.splice(index, 1);
    },
    addHistoricLayer: (state, action: PayloadAction<string>) => {
      state.session.historic_layers = [
        {
          layer_id: action.payload,
          display_meta: false,
          opacity: 1,
        },
      ];
    },
    removeHistoricLayer: (state, action: PayloadAction<string>) => {
      const index = state.session.historic_layers.findIndex(
        (layer) => layer.layer_id === action.payload
      );
      //const f_index = state.cache.findIndex(c => c.layer_id === action.payload.id);
      //if (f_index !== -1)
      //state.cache[f_index] = state.session.data.historic_layers[index]
      //else
      //state.cache.push(state.session.data.historic_layers[index]);
      state.session.historic_layers.splice(index, 1);
    },
    setHistoricLayerOpacity: (
      state,
      action: PayloadAction<{ layer_id: string; opacity: number }>
    ) => {
      const layer = state.session.historic_layers.find(
        (layer) => layer.layer_id === action.payload.layer_id
      );
      if (layer) {
        layer.opacity = action.payload.opacity;
      }
    },
    setHistoricLayerMeta: (
      state,
      action: PayloadAction<{ layer_id: string; display_meta: boolean }>
    ) => {
      const layer = state.session.historic_layers.find(
        (layer) => layer.layer_id === action.payload.layer_id
      );
      if (layer) {
        layer.display_meta = action.payload.display_meta;
      }
    },
    clearSession: (state) => {
      state.session = initialSession;
      state.layers = initialLayers;
      state.cache = initialСache;
      state.panelsOpenStates = initialPanelsOpen;
      state.features = [];
      state.locatorCountry = "";
      state.acceptLayer = null;
      state.order_report = initialOrderReport;
    },
    setReference: (state, action: PayloadAction<string>) => {
      state.session.reference = state.session.reference = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.session.zoom = action.payload;
    },
    setCenter: (state, action: PayloadAction<number[]>) => {
      state.session.position = action.payload;
    },
    setFeatures: (state, action: PayloadAction<ISessionFeature[]>) => {
      state.session.features = state.session.features = action.payload;
    },
    addFeature: (state, action: PayloadAction<ISessionFeature>) => {
      state.session.features.push(action.payload);
    },
    setUnderwritingTitleLabel: (
      state,
      action: PayloadAction<{ titleNumber: string; label: string }>
    ) => {
      const foundTitle = state.session.session_underwriting?.Titles.find(
        (title) => title.TitleNumber === action.payload.titleNumber
      );
      if (foundTitle) {
        foundTitle.LabelName = action.payload.label;
      }
    },

    setUnderwritingTitleColor: (
      state,
      action: PayloadAction<{
        titleNumber: string;
        color: string;
        opacity?: number;
      }>
    ) => {
      const foundTitle = state.session.session_underwriting?.Titles.find(
        (title) => title.TitleNumber === action.payload.titleNumber
      );
      if (foundTitle) {
        foundTitle.Fill = action.payload.color;
        foundTitle.Opacity = action.payload.opacity;
      }
    },
    setUnderwritingTitleHighlight: (
      state,
      action: PayloadAction<{ titleNumber: string; highlight: boolean }>
    ) => {
      const foundTitle = state.session.session_underwriting?.Titles.find(
        (title) => title.TitleNumber === action.payload.titleNumber
      );
      if (foundTitle) {
        foundTitle.Highlight = action.payload.highlight;
      }
    },
    setUnderwritingShowOnAllTabs: (
      state,
      action: PayloadAction<{ show: boolean }>
    ) => {
      if (!state.session.session_underwriting) return;

      state.session.session_underwriting.ShowOnAllTabs = action.payload.show;
    },
    setUnderwritingPlaceDDAOrder: (
      state,
      action: PayloadAction<{ placeDDAOrder: boolean }>
    ) => {
      if (!state.session.session_underwriting) return;

      state.session.session_underwriting.PlaceDDAOrder =
        action.payload.placeDDAOrder;
    },

    setUndewritingTitleVisibility: (
      state,
      action: PayloadAction<{ titleNumbers: Array<string>; visible: boolean }>
    ) => {
      if (!state.session.session_underwriting) return;

      state.session.session_underwriting.Titles.forEach((title) => {
        if (action.payload.titleNumbers.includes(title.TitleNumber))
          title.TitleHidden = !action.payload.visible;
      });
    },

    setUndewritingHideAllTitles: (state, action: PayloadAction<boolean>) => {
      if (!state.session.session_underwriting) return;

      state.session.session_underwriting.HideAllTitles = action.payload;
    },

    setUndewritingHideAllGroups: (state, action: PayloadAction<boolean>) => {
      if (!state.session.session_underwriting) return;

      state.session.session_underwriting.HideAllGroups = action.payload;
    },

    setUnderwritingGroupNames: (
      state,
      action: PayloadAction<{ groupAName: string; groupBName: string }>
    ) => {
      if (!state.session.session_underwriting) return;
      if (action.payload.groupAName) {
        state.session.session_underwriting.GroupAName =
          action.payload.groupAName;
      }
      if (action.payload.groupBName) {
        state.session.session_underwriting.GroupBName =
          action.payload.groupBName;
      }
    },

    setUnderwritingGroup: (
      state,
      action: PayloadAction<{
        titleNumber: string;
        group: UnderwritingTitleGroup;
      }>
    ) => {
      const foundTitle = state.session.session_underwriting?.Titles.find(
        (title) => title.TitleNumber === action.payload.titleNumber
      );
      if (foundTitle) foundTitle.Group = action.payload.group;
    },

    setModuleId: (state, action: PayloadAction<ModuleTypes>) => {
      state.session.module_id = state.session.module_id = action.payload;
    },
    setBaseLayer: (state, action: PayloadAction<string>) => {
      state.session.base.layer_id = action.payload;
    },
    setFiltersPanelOpenState: (state, action: PayloadAction<boolean>) => {
      state.panelsOpenStates.isFiltersPanelOpen = action.payload;
    },
    setOrderPanelOpenState: (state, action: PayloadAction<boolean>) => {
      state.panelsOpenStates.isOrderPanelOpen = action.payload;
    },
    setTravelTimeMode: (state, action: PayloadAction<ITravelTime>) => {
      state.travelTimeModeOptions = action.payload;
    },
    setUnderwrittingMode: (state, action: PayloadAction<boolean>) => {
      state.session.underwriting_mode = action.payload;
    },
    setOrderReportMode: (state, action: PayloadAction<boolean>) => {
      state.session.order_report_mode = action.payload;
    },
    setStorymap: (state, action: PayloadAction<boolean>) => {
      state.storymap = action.payload;
    },
    setOpacity: (
      state,
      action: PayloadAction<{ layer_id: string; value: number }>
    ) => {
      const layer = state.session.layers.find(
        (c) => c.layer_id === action.payload.layer_id
      );
      if (layer) {
        layer.opacity = action.payload.value;
      }
    },
    resetFilter: (state, action: PayloadAction<string>) => {
      const layer = state.session.layers.find(
        (c) => c.layer_id === action.payload
      );
      if (layer) layer.filters = [];
    },
    setFilter: (
      state,
      action: PayloadAction<{ layer_id: string; filters: IFilterValue[] }>
    ) => {
      const layer = state.session.layers.find(
        (c) => c.layer_id === action.payload.layer_id
      );
      if (layer) {
        action.payload.filters.forEach((filter) => {
          if (!layer.filters) layer.filters = [];
          const index = layer.filters.findIndex(
            (c) => c.field === filter.field && c.type === filter.type
          );
          switch (filter.type) {
            case FilterType.Multitext: {
              const { type, field, searchTerms } = filter;
              if (index !== -1) layer.filters.splice(index, 1);
              layer.filters.push({ type, field, searchTerms });
              break;
            }
            case FilterType.ILike: {
              const { type, field, searchTerms } = filter;
              if (index !== -1) layer.filters.splice(index, 1);
              layer.filters.push({ type, field, searchTerms });
              break;
            }
            case FilterType.Selection:
            case FilterType.Text: {
              const { type, field, value } = filter;
              if (index !== -1) layer.filters.splice(index, 1);
              layer.filters.push({ type, field, value });
              break;
            }
            case FilterType.Date: {
              const { type, field, from, to } = filter;
              if (index !== -1) {
                layer.filters[index].from = filter.from;
                layer.filters[index].to = filter.to;
              } else {
                layer.filters.push({ type, field, from, to });
              }
              break;
            }
            case FilterType.Boolean: {
              const { type, field, value } = filter;
              if (index !== -1) layer.filters.splice(index, 1);
              layer.filters.push({ type, field, value: `${value}` });
              break;
            }
          }
        });
      }
    },
    addUpdateFeatures: (state, action: PayloadAction<IFeature[]>) => {
      state.features = action.payload;
    },
    addOrderReportTitle: (
      state,
      action: PayloadAction<IAddOrderReportTitle>
    ) => {
      state.session.session_order_report = {
        ...state.session.session_order_report,
        Tenure: action.payload.Tenure,
        TitleNumber: action.payload.TitleNumber,
        WKT: action.payload.WKT,
        Products: [],
        Client: null,
        ProjectId: null,
        Insurer: null,
        CcReport: null,
        Address: action.payload.address ? action.payload.address : null,
        ProductLimitOverrided: false,
      };
    },
    addOrderReportDetails: (
      state,
      action: PayloadAction<IAddOrderReportDetails>
    ) => {
      state.session.session_order_report = {
        ...state.session.session_order_report,
        ...action.payload,
      };
    },
    addRemoveOrderReport: (
      state,
      action: PayloadAction<{ ReportProductId: number; State: "added" }>
    ) => {
      const session_order_report = state.session.session_order_report;
      if (session_order_report && session_order_report.Products) {
        let products = session_order_report.Products;
        const reportExists = products.some(
          ({ ReportProductId }) =>
            ReportProductId === action.payload.ReportProductId
        );
        if (reportExists) {
          products = products.filter(
            ({ ReportProductId }) =>
              ReportProductId !== action.payload.ReportProductId
          );
        } else {
          products.push(action.payload);
        }
        state.session.session_order_report = {
          ...state.session.session_order_report,
          Products: products,
        };
      } else {
        state.session.session_order_report = {
          ...state.session.session_order_report,
          Products: [action.payload],
        };
      }
    },
    addOrderReportMaxSqm: (state, action: PayloadAction<number>) => {
      state.order_report.maxSqm = action.payload;
    },
    addOrderReportMaxAddressCount: (state, action: PayloadAction<number>) => {
      state.order_report.maxAddressCount = action.payload;
    },
    setLocatorSearch: (
      state,
      action: PayloadAction<{ searched: boolean; country?: string }>
    ) => {
      state.usedLocatorSearch = action.payload.searched;
      if (
        action.payload.country !== null &&
        action.payload.country !== undefined
      ) {
        state.locatorCountry = action.payload.country;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSession.fulfilled, (state, action) => {
      state.initialSession = {
        ...action.payload,
      };
      state.session = {
        ...action.payload,
      };
    });
    builder.addCase(getLayers.fulfilled, (state, action) => {
      if (action.payload) {
        state.layers = action.payload;
        const baseGroup = action.payload.groups.find(
          (c) => c.name === "BASEMAPS"
        );
        if (
          !state.session.base.layer_id &&
          baseGroup &&
          baseGroup.layers.length > 0
        )
          state.session.base.layer_id = baseGroup.layers[0].name;
      }
    });
    builder.addCase(saveSession.rejected, (state, action) => {
      notification("error", action.error.message ?? "");
      if (
        state.session.order_report_mode &&
        state.session.session_order_report?.PlaceOrders
      ) {
        state.order_report.loading = false;
      }
    });
    builder.addCase(saveSession.pending, (state) => {
      if (
        state.session.order_report_mode &&
        state.session.session_order_report?.PlaceOrders
      ) {
        state.order_report.loading = true;
      }
    });
    builder.addCase(saveSession.fulfilled, (state, action) => {
      state.initialSession = action.payload;
      state.session.id = state.session.id = action.payload.id;
      state.session.features = state.session.features = action.payload.features;
      state.session.session_underwriting = state.session.session_underwriting =
        action.payload.session_underwriting;

      state.session.underwriting_mode = state.session.underwriting_mode =
        action.payload.underwriting_mode;
      state.session.order_report_mode = state.session.order_report_mode =
        action.payload.order_report_mode;

      if (state.order_report.loading) {
        state.order_report.loading = false;
      }
    });
    builder.addCase(lookupAddress.fulfilled, (state, action) => {
      if (action.payload.addresses.length > 0) {
        const title = state.session.session_underwriting?.Titles.find(
          (c) => c.TitleNumber === action.payload.titleNumber
        );
        if (title) title.Address = action.payload.addresses[0].AddressString;
      }
    });
  },
});

export const {
  setLayers,
  setAcceptLayer,
  setGreyScale,
  setFeatures,
  addFeature,
  addLayer,
  addDropdownLayer,
  setActiveTab,
  removeLayer,
  addHistoricLayer,
  removeHistoricLayer,
  setHistoricLayerOpacity,
  setHistoricLayerMeta,
  setHistoricLayers,
  clearSession,
  setReference,
  setZoom,
  setCenter,
  setBaseLayer,
  setFilter,
  resetFilter,
  setOpacity,
  setModuleId,
  setFiltersPanelOpenState,
  setOrderPanelOpenState,
  setTravelTimeMode,
  setUnderwrittingMode,
  setOrderReportMode,
  setStorymap,
  setUnderwrittingPanelActive,
  setOpenLocator,
  addUnderwritingTitle,
  removeUnderwritingTitle,
  setUnderwritingTitleLabel,
  setUnderwritingTitleColor,
  setUnderwritingGroupNames,
  setUnderwritingGroup,
  setUnderwritingTitleHighlight,
  setUnderwritingShowOnAllTabs,
  setUnderwritingPlaceDDAOrder,
  setUndewritingTitleVisibility,
  setUndewritingHideAllTitles,
  setUndewritingHideAllGroups,
  setIsNewSession,
  addUpdateFeatures,
  addOrderReportTitle,
  addOrderReportDetails,
  addRemoveOrderReport,
  addOrderReportMaxSqm,
  addOrderReportMaxAddressCount,
  setLocatorSearch,
} = conveymapSlice.actions;
export default conveymapSlice.reducer;
