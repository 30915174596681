import "./button.scss";
import React from "react";
import { ButtonHTMLAttributes, ReactNode } from "react";
import AppIcons from "components/app-icons/app-icons";
import LoadingSpinner from "components/loading-spinner/loading-spinner";

type Color =
  | "primary"
  | "secondary"
  | "highlight"
  | "warning"
  | "danger"
  | "default"
  | "orange"
  | "disabled";
type ButtonIcon =
  | "down"
  | "next"
  | "prev"
  | "check"
  | "left"
  | "none"
  | "cancel";

type IconPosition = "left" | "right";
type Size = "small" | "normal" | "large";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  color?: Color;
  icon?: ButtonIcon | ReactNode;
  iconPosition?: IconPosition;
  buttonSize?: Size;
  flat?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  iconPosition = "right",
  text = "",
  color = "default",
  icon = "none",
  buttonSize = "normal",
  flat = false,
  loading = false,
  ...props
}) => {
  let Icon = null;
  const iconColor = props.disabled
    ? "disabled"
    : color === "disabled"
      ? "disabled"
      : color === "default"
        ? "primary"
        : "default";
  switch (icon) {
    case "down":
      Icon = <AppIcons.ChevronRight color={iconColor} />;
      break;
    case "next":
      Icon = <AppIcons.ChevronRight color={iconColor} />;
      break;
    case "left":
      Icon = <AppIcons.ChevronLeft color={iconColor} />;
      break;
    case "prev":
      Icon = <AppIcons.BackArrow color={iconColor} />;
      break;
    case "check":
      Icon = <AppIcons.Check color={iconColor} />;
      break;
    case "binoculars":
      Icon = <AppIcons.BinocularsWhite color={iconColor} />;
      break;
    case "close-white":
      Icon = <AppIcons.WhiteClose />;
      break;
    case "cancel":
      Icon = <AppIcons.Cancel35 />;
      break;
    case "streetview":
      Icon = <AppIcons.StreetviewButton />;
      break;
    case "upload":
      Icon = <AppIcons.Add />;
      break;
    case "none":
      break;
    default:
      Icon = icon;
  }

  return (
    <button
      {...props}
      className={`cls-btn cls-btn-${color} cls-btn-${buttonSize} ${iconPosition} ${
        flat && "cls-btn-flat"
      } ${props.className ?? ""}`}
    >
      <span className="button-text">{text ? text : props.children}</span>
      {loading ? (
        <div>
          <LoadingSpinner size={18} color={"secondary"} />
        </div>
      ) : (
        Icon
      )}
    </button>
  );
};

export default Button;
